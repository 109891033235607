"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import { ArrowRight } from "lucide-react";
import {
  Code,
  Smartphone,
  Layers,
  ShoppingCart,
  Pen,
  FileText,
  Cloud,
  TrendingUp,
  Brain
} from "lucide-react";

// Services data with additional image placeholders
// You'll replace these placeholder images with your actual images
const services = [
  {
    title: "Custom Software",
    href: "/services/custom-software/",
    icon: Code,
    description: "Build tailored software solutions that perfectly align with your business needs and objectives.",
    image: "/images/custom-software-home.jpg" // Replace with your image
  },
  {
    title: "Mobile Apps",
    href: "/services/mobile-app/",
    icon: Smartphone,
    description: "Create engaging mobile experiences for iOS and Android platforms with cutting-edge technology.",
    image: "/images/mobile-apps-home.jpg" // Replace with your image
  },
  {
    title: "Full-Stack Development",
    href: "/services/full-stack/",
    icon: Layers,
    description: "End-to-end development services covering both frontend and backend requirements.",
    image: "/images/full-stack-home.jpg" // Replace with your image
  },
  {
    title: "eCommerce",
    href: "/services/ecommerce/",
    icon: ShoppingCart,
    description: "Build scalable online stores with seamless shopping experiences and secure payments.",
    image: "/images/ecommerce-home.jpg" // Replace with your image
  },
  {
    title: "UI & UX Design",
    href: "/services/ui-ux-design/",
    icon: Pen,
    description: "Create intuitive and visually appealing interfaces that delight your users.",
    image: "/images/ui-ux-home.jpg" // Replace with your image
  },
  {
    title: "CMS Development",
    href: "/services/cms-development/",
    icon: FileText,
    description: "Develop custom content management systems that make content updates effortless.",
    image: "/images/cms-development-home.jpg" // Replace with your image
  },
  {
    title: "Cloud Services",
    href: "/services/cloud/",
    icon: Cloud,
    description: "Implement robust cloud solutions for improved scalability and performance.",
    image: "/images/cloud-services-home.jpg" // Replace with your image
  },
  {
    title: "Digital Marketing",
    href: "/services/digital-marketing/",
    icon: TrendingUp,
    description: "Drive growth with data-driven digital marketing strategies and campaigns.",
    image: "/images/digital-marketing-home.jpg" // Replace with your image
  },
  {
    title: "AI & Machine Learning",
    href: "/tech/ai-ml/",
    icon: Brain,
    description: "Leverage generative AI models and machine learning frameworks to drive innovation.",
    image: "/images/ai-ml-home.jpg" // Replace with your image
  },
];

const ServiceItem = ({ service, index }) => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.4, delay: 0.05 * (index % 6) }}
      className="rounded-3xl overflow-hidden bg-white shadow-sm flex flex-col h-full border border-blue-50 group"
    >
      <div className="flex flex-col md:flex-row h-full">
        {/* Content Section (Left) */}
        <div className="p-5 md:p-6 flex-1 flex flex-col justify-between">
          <div>
            {/* Icon with hover effect */}
            <div className="w-12 h-12 rounded-xl p-2.5 mb-4 bg-blue-100 group-hover:bg-blue-600 transition-colors duration-300">
              <service.icon className="h-full w-full text-blue-600 group-hover:text-white transition-colors duration-300" />
            </div>
            <h3 className="text-lg font-bold mb-2 group-hover:text-blue-600 transition-colors duration-300">{service.title}</h3>
            <p className="text-gray-600 text-sm mb-4">{service.description}</p>
          </div>
          
          <Link 
            href={service.href}
            className="inline-flex items-center text-blue-600 text-sm font-medium hover:text-blue-800"
          >
            Read More <ArrowRight className="ml-1 w-3.5 h-3.5 group-hover:translate-x-1 transition-transform duration-300" />
          </Link>
        </div>
        
        {/* Image Section (Right) */}
        <div className="md:w-28 lg:w-36 h-32 md:h-auto relative overflow-hidden">
          <Image
            src={service.image}
            alt={service.title}
            fill
            className="object-cover transition-transform duration-500 group-hover:scale-110"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 30vw, 20vw"
          />
        </div>
      </div>
    </motion.div>
  );
};

const ThreePerRowServices = () => {
  return (
    <section className="py-20 bg-blue-50/50">
      <div className="container mx-auto px-4">
        <div className="mb-16">
          <div className="flex items-center justify-start mb-4">
          
            <div className="inline-flex items-center gap-2 bg-blue-600/10 rounded-full px-4 py-2"><span className="text-blue-600 animate-pulse">●</span><span className="text-blue-600 text-xs md:text-sm">Latest Services</span></div>
          </div>
          
          <div className="md:flex md:justify-between md:items-center">
            <div className="mb-8 md:mb-0 md:max-w-xl">
              <h2 className="text-4xl md:text-5xl font-bold text-gray-900 ">
                Solutions We Provide
              </h2>
            </div>
            
            <div className="md:max-w-lg">
              <p className="text-gray-600">
                Our tailored marketing solutions are designed to meet your unique
                business needs from market analysis to digital strategy
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <ServiceItem 
              key={service.title} 
              service={service} 
              index={index} 
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ThreePerRowServices;