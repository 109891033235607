"use client";
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Search, Palette, Code, Rocket } from 'lucide-react';

const processSteps = [
  {
    icon: Search,
    title: "Discovery & Planning",
    description: "We gather requirements and develop a strategic plan for your project."
  },
  {
    icon: Palette,
    title: "Design ",
    description: "Our team creates intuitive UI/UX designs and robust system architecture."
  },
  {
    icon: Code,
    title: "Development & Testing",
    description: "We bring your project to life with quality code and rigorous testing."
  },
  {
    icon: Rocket,
    title: "Launch & Support",
    description: "Your project goes live with ongoing maintenance and support."
  },
];

const WorkProcessSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.8,
      },
    },
  };

  return (
    <section ref={ref} className="py-24 relative overflow-hidden">
 

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
          <motion.div className="text-center mb-16" variants={itemVariants}>
            <h2 className="text-3xl font-bold sm:text-6xl tracking-tighter mb-6">
              Our Work Process
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              A Streamlined Approach to Bringing Your Ideas to Life
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {processSteps.map((step, index) => (
              <motion.div
                key={step.title}
                variants={itemVariants}
                className="group relative"
              >
                <div className="relative bg-white/80 backdrop-blur-lg p-8 rounded-3xl border border-blue-100/50 
                  transition-all duration-300 hover:border-blue-200 hover:shadow-lg hover:-translate-y-1">
                  
                  {/* Icon container */}
                  <div className="bg-blue-50 w-14 h-14 rounded-2xl flex items-center justify-center mb-6
                    transition-transform duration-300 group-hover:scale-110">
                    <step.icon className="w-7 h-7 text-blue-600" />
                  </div>

                  {/* Content */}
                  <h3 className="text-xl font-semibold mb-3 transition-colors duration-300 group-hover:text-blue-600">
                    {step.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    {step.description}
                  </p>

                  {/* Step number */}
                  <div className="absolute top-4 right-6 text-7xl font-bold text-blue-100/50 transition-colors duration-300 group-hover:text-blue-200/50">
                    {index + 1}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            className="mt-16 text-center"
            variants={itemVariants}
          >
            <a
              href="/process/"
              className="inline-flex items-center gap-2 px-8 py-4 rounded-full font-medium 
                bg-gradient-to-r from-blue-600 to-purple-600 text-white
                hover:shadow-lg hover:shadow-blue-500/25 transition-all duration-300 hover:-translate-y-1"
            >
              Learn More About Our Process
              <Rocket className="w-4 h-4" />
            </a>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default WorkProcessSection;