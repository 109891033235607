"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import {
  ArrowRight,
  Code,
  Globe,
  Lightbulb,
  CheckCircle,
  Search,
  ChevronLeft,
  ChevronRight,
  Server,
  Smartphone,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const InfywaysHeader = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Slides data for the header
  const slides = [
    {
      image: "/images/digital-innovation.jpg",
      title: "Transform Your Digital Future",
      description:
        "Empowering businesses with cutting-edge solutions that drive growth and innovation in the digital age.",
    },
    {
      image: "/images/custom-software-development.jpg",
      title: "Software Development",
      description:
        "Build tailored software solutions that perfectly align with your business needs and objectives.",
    },
    {
      image: "/images/mobile-application-development.jpg",
      title: "Mobile App Development",
      description:
        "Create engaging mobile experiences for iOS and Android platforms with cutting-edge technology.",
    },
    {
      image: "/images/full-stack-development.jpg",
      title: "Full-Stack Development",
      description:
        "End-to-end development services covering both frontend and backend requirements.",
    },
    {
      image: "/images/ecommerce.jpg",
      title: "eCommerce Development",
      description:
        "Build scalable online stores with seamless shopping experiences and secure payments.",
    },
    {
      image: "/images/ui-ux.jpg",
      title: "UI & UX Design",
      description:
        "Create intuitive and visually appealing interfaces that delight your users.",
    },
    {
      image: "/images/cms-development.jpg",
      title: "CMS Development",
      description:
        "Develop custom content management systems that make content updates effortless.",
    },
    {
      image: "/images/cloud-services.jpg",
      title: "Cloud Services",
      description:
        "Implement robust cloud solutions for improved scalability and performance.",
    },
    {
      image: "/images/digital-marketing.jpg",
      title: "Digital Marketing",
      description:
        "Drive growth with data-driven digital marketing strategies and campaigns.",
    },
    {
      image: "/images/ai-technologies.jpg",
      title: "AI and ML ",
      description:
        "Leverage generative AI models and machine learning frameworks to drive innovation in content creation, automation, and business growth.",
    },
  ];

  // Key features to highlight - arrange them by length to optimize grid layout
  const keyFeatures = [
    "UI & UX Design",
    "Cloud Services",
    "CMS Development",
    "Digital Marketing",
    "Full-Stack Development",
    "eCommerce Development",
    "Mobile App Development",
    "Custom Software Development",
    "AI and Machine Learning Development",
  ];

  // Stats to display
  const stats = [
    { value: "14+", label: "Years Experience" },
    { value: "1500+", label: "Projects Delivered" },
    { value: "500+", label: "Clients Worldwide" },
  ];

  // Stats icons
  const statsIcons = [
    <Code className="w-6 h-6" key="code" />,
    <CheckCircle className="w-6 h-6" key="check" />,
    <Globe className="w-6 h-6" key="globe" />,
  ];

  useEffect(() => {
    setIsVisible(true);
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [slides.length]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative overflow-hidden">
      {/* Background with fade effect */}
      <div className="absolute inset-0 ">
        {/* Background Pattern */}
       <div className="absolute inset-0" style={{
  backgroundImage: 'linear-gradient(to right, white, transparent, white), radial-gradient(#2563eb 4px, transparent 4px)',
  backgroundSize: 'auto, 30px 30px',
  opacity: 0.03
}} />
        
        {/* Gradient fade to white at bottom */}
        <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white via-white/90 to-transparent" />
      </div>

      <div className="relative z-10">
        {/* Main Content */}
        <div className="container mx-auto px-4 flex items-center py-12 md:py-16">
          <div className="grid lg:grid-cols-2 gap-8 md:gap-12 lg:gap-16 items-center w-full">
            {/* Left Column */}
            <div
              className={`space-y-6 md:space-y-8 transform transition-all duration-1000 ${
                isVisible
                  ? "translate-x-0 opacity-100"
                  : "-translate-x-20 opacity-0"
              }`}
            >
              <div className="inline-flex items-center gap-2 bg-blue-600/10 rounded-full px-4 py-2">
                <span className="text-blue-600 animate-pulse">●</span>
                <span className="text-blue-600 text-xs md:text-sm">
                  Next-Gen Technology Solutions
                </span>
              </div>

              {/* Fixed height container to prevent layout shifts */}
              <div className="min-h-[180px] md:min-h-[240px] relative">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={currentSlide}
                    initial={{ opacity: 0, y: 20, position: "absolute" }}
                    animate={{ opacity: 1, y: 0, position: "absolute" }}
                    exit={{ opacity: 0, y: -20, position: "absolute" }}
                    transition={{
                      duration: 0.4,
                      opacity: { duration: 0.3 },
                      y: { duration: 0.3 },
                    }}
                    className="w-full"
                  >
                    <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold leading-tight">
                      <span className="text-gray-900">
                        {slides[currentSlide].title.split(" ")[0]}
                      </span>
                      <br />
                      <span className="text-blue-600">
                        {slides[currentSlide].title
                          .split(" ")
                          .slice(1)
                          .join(" ")}
                      </span>
                    </h1>

                    <p className="text-gray-600 text-sm md:text-base lg:text-lg max-w-xl mt-4 md:mt-6">
                      {slides[currentSlide].description}
                    </p>
                  </motion.div>
                </AnimatePresence>
              </div>

             {/* Features Grid */}
             <div className="grid grid-cols-2 gap-3 md:gap-4">
                {keyFeatures.map((feature, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 text-gray-600 text-sm md:text-base"
                  >
                    <div className="w-4 h-4 md:w-5 md:h-5 rounded-full bg-blue-600/10 flex items-center justify-center">
                      <CheckCircle className="w-3 h-3 md:w-3 md:h-3 text-blue-600" />
                    </div>
                    {feature}
                  </div>
                ))}
              </div>
              {/* CTAs */}
              <div className="flex flex-col sm:flex-row gap-4 pt-4 md:pt-6">
                <Link
                  href="/contact"
                  className="group relative px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white rounded-xl font-medium overflow-hidden w-full sm:w-auto inline-flex items-center justify-center transition-all duration-300 hover:bg-blue-700 hover:shadow-lg hover:-translate-y-1"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-600 translate-x-[-100%] group-hover:translate-x-0 transition-transform duration-300" />
                  <span className="relative flex items-center justify-center gap-2">
                    Get Started Now
                    <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                  </span>
                </Link>

                <Link
                  href="/tools"
                  className="px-6 md:px-8 py-3 md:py-4 border border-blue-600 text-blue-600 rounded-xl font-medium hover:bg-blue-600/5 transition-colors flex items-center justify-center gap-2 w-full sm:w-auto"
                >
                  <Search className="w-5 h-5" />
                  Explore Tools
                </Link>
              </div>

              {/* Stats Section */}
              <div className="grid grid-cols-3 gap-4 p-4 mt-6 bg-white rounded-xl shadow-sm">
                {stats.map((stat, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className="flex items-center gap-2 mb-1">
                      <div className="text-blue-600">{statsIcons[index]}</div>
                      <div className="font-bold text-gray-900 text-xl">
                        {stat.value}
                      </div>
                    </div>
                    <div className="text-xs md:text-sm text-gray-500 text-center">
                      {stat.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Column - Image Slider */}
            <div className="relative aspect-[4/3] md:aspect-square w-full mt-4 md:mt-0">
              <AnimatePresence mode="wait">
                {slides.map(
                  (slide, index) =>
                    currentSlide === index && (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ duration: 0.5 }}
                        className="absolute inset-0"
                      >
                        <div className="relative h-full rounded-2xl overflow-hidden shadow-2xl">
                          {/* Fallback if images don't load */}
                          <div className="absolute inset-0 bg-blue-50/10 flex items-center justify-center z-0">
                            <p className="text-blue-600 text-xl font-medium text-center px-8">
                              Next-Generation Web Development
                            </p>
                          </div>

                          <Image
                            src={slide.image}
                            alt={slide.title}
                            fill
                            sizes="(max-width: 768px) 100vw, 50vw"
                            className="object-cover relative z-10"
                            priority={index === 0}
                            onError={(e) => {
                              // Hide image on error
                              const target = e.target as HTMLImageElement;
                              target.style.opacity = "0";
                            }}
                          />
                          <div className="absolute inset-0 bg-gradient-to-tr from-gray-900/20 to-transparent z-20" />
                        </div>
                      </motion.div>
                    )
                )}
              </AnimatePresence>

              {/* Slider Controls */}
              <div className="absolute bottom-4 right-4 flex items-center gap-2 z-30">
                <button
                  onClick={prevSlide}
                  className="p-1.5 md:p-2 rounded-full bg-white/20 backdrop-blur-sm hover:bg-white/30 transition-colors"
                >
                  <ChevronLeft className="w-4 h-4 md:w-5 md:h-5 text-white" />
                </button>
                <div className="flex gap-1">
                  {slides.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentSlide(index)}
                      className={`h-1.5 md:h-2 rounded-full transition-all ${
                        currentSlide === index
                          ? "w-4 md:w-6 bg-white"
                          : "w-1.5 md:w-2 bg-white/50"
                      }`}
                    />
                  ))}
                </div>
                <button
                  onClick={nextSlide}
                  className="p-1.5 md:p-2 rounded-full bg-white/20 backdrop-blur-sm hover:bg-white/30 transition-colors"
                >
                  <ChevronRight className="w-4 h-4 md:w-5 md:h-5 text-white" />
                </button>
              </div>

              {/* Floating Feature Cards */}
              <div className="absolute -top-4 -left-4 md:top-6 md:-left-6 bg-white p-3 md:p-4 rounded-lg shadow-lg z-30 hidden md:block">
                <div className="flex items-center gap-3">
                  <div className="bg-blue-100 p-2 rounded-full">
                    <Code className="h-5 w-5 text-blue-600" />
                  </div>
                  <div>
                    <div className="text-sm font-medium">
                      Custom Software Development
                    </div>
                    <div className="text-xs text-gray-500">
                      Tailored solutions for your business needs
                    </div>
                  </div>
                </div>
              </div>

              <div className="absolute -bottom-4 -right-4 md:bottom-20 md:-right-12 bg-white p-3 md:p-4 rounded-lg shadow-lg z-30 hidden md:block">
                <div className="flex items-center gap-3">
                  <div className="bg-green-100 p-2 rounded-full">
                    <Smartphone className="h-5 w-5 text-green-600" />
                  </div>
                  <div>
                    <div className="text-sm font-medium">
                      Mobile App Development
                    </div>
                    <div className="text-xs text-gray-500">
                      Engaging apps for iOS and Android
                    </div>
                  </div>
                </div>
              </div>

              {/* Decorative Border */}
              <motion.div
                className="absolute -z-10 w-full h-full rounded-2xl border-2 border-blue-200 top-8 left-8"
                animate={{
                  scale: [1, 1.02, 1],
                  rotate: [0, 1, 0],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Bottom fade overlay - adds an additional fade effect at the component level */}
      <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent z-20"></div>
    </div>
  );
};

export default InfywaysHeader;