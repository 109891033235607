"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { ArrowRight, ArrowUpRight } from "lucide-react";

const LatestPosts = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setIsLoading(true);
        const response = await fetch("/api/posts");

        if (!response.ok) {
          throw new Error(`Error fetching posts: ${response.status}`);
        }

        const data = await response.json();
        // Get only the first 3 posts
        setPosts(data.posts.slice(0, 3));
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch posts:", error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // Function to format date for display
  const formatDate = () => {
    // Example date format shown in your screenshot
    return "December 28, 2018";
  };

  if (isLoading) {
    return (
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">
            Strategy and Insights
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[1, 2, 3].map((item) => (
              <div
                key={item}
                className="bg-white rounded-lg shadow animate-pulse h-96"
              ></div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">
            Strategy and Insights
          </h2>
          <div className="text-center text-red-600">
            <p>Failed to load posts. Please try again later.</p>
            <p>{error}</p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <div className="flex justify-center">
          <span className="inline-block bg-blue-600/10 text-blue-600 px-4 py-2 rounded-full text-sm font-medium mb-6">
          <span className="text-blue-600 animate-pulse">●</span>  Latest Blog Posts
          </span>
        </div>
        <h2 className="text-4xl font-bold text-center mb-16">
         
Strategy and Insights

        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {posts.map((post) => (
            <article
              key={post.post_id}
              className="bg-white overflow-hidden rounded-3xl border border-blue-50 transition-all duration-300 hover:shadow-lg group"
            >
              <Link href={`/${post.slug}`} className="block relative">
                <div className="relative w-full h-56 overflow-hidden">
                  {/* Image */}
                  <Image
                    src={post.featured_image}
                    alt={post.title}
                    fill
                    className="object-cover transition-transform duration-500 group-hover:scale-105"
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  />

                  {/* Hover overlay */}
                  <div className="absolute inset-0 bg-blue-600/0 group-hover:bg-blue-600/60 transition-all duration-300 flex items-center justify-center opacity-0 group-hover:opacity-100">
                    <div className="h-14 w-14 rounded-full bg-white flex items-center justify-center transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300">
                      <ArrowUpRight className="h-6 w-6 text-blue-600" />
                    </div>
                  </div>
                </div>
              </Link>

              <div className="p-6">
                <div className="flex items-center space-x-2 mb-2 text-sm">
                  <span className="text-gray-600">{post.date}</span>
                  {post.date && <span className="mr-1">-</span>}
                  {post.category_names &&
                    post.category_names.map((category, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && ", "}
                        <span className="text-blue-600">{category}</span>
                      </React.Fragment>
                    ))}
                </div>

                <h3 className="text-xl font-bold mb-6 min-h-[3.5rem] line-clamp-2">
                  <Link
                    href={`/${post.slug}`}
                    className="hover:text-blue-600 transition-colors"
                  >
                    {post.title}
                  </Link>
                </h3>

                <Link
                  href={`/${post.slug}`}
                  className="inline-flex items-center text-blue-600 font-medium hover:text-blue-800 group-hover:underline"
                >
                  Learn More
                  <ArrowRight className="ml-2 w-4 h-4 transition-transform group-hover:translate-x-1" />
                </Link>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LatestPosts;
