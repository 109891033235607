import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Footer/CTASection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Header/HeaderHome.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Main/LatestPosts.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Main/ServiceShowcase.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Main/TechStack.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Main/TestimonialSlider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/Main/WorkProcess.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
